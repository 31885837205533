import React, {useState} from "react";
import { MethodTabsFrontMatter } from "types/graphql";
import Section from "../section";
import classNames from "classnames/bind";
import {isDesktop} from 'react-device-detect';
import PlusIcon from "assets/svg/plus.svg";

type cardValuesProps = Omit<MethodTabsFrontMatter, "format"> & {
  content: string;
};

const cardValues = ({ listicon,listtitle, listcontent, content }: cardValuesProps) => {

  const [isActive, setActive] = useState<string>();
  const handleClick = (item:string, index:number) => {
    setActive(index)
  }

  return (
    <Section className="relative flex flex-col lg:pb-32" full>
      <span
        className="block mb-4 lg:mb-10 text-center "
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-3">
        {
          listtitle && listtitle.map((item: string, index: number) => {
          return (
            <button
              type="button"
              key={index}
              onClick={ isDesktop ? null : () => handleClick(item,index) }
              className={classNames("group bg-gray-50 h-56 relative flex flex-col overflow-hidden rounded-lg hover:bg-white hover:border hover:border-gray-100 p-2 transition-all duration-03 ease-ease", {
                  "active-value" : isActive === index,
                }
              )}
            >
              { listicon &&
                <figure className="w-10 mb-1 mx-auto transition-all duration-03 ease-ease translate-y-14 group-hover:translate-y-6 group-hover:w-6">
                  <img src={listicon[index]} alt="icon" />
                </figure>
              }
              <span className="w-full h3 transition-all duration-02 ease-ease translate-y-14 group-hover:translate-y-10 group-hover:opacity-0 text-center">
                {item}
              </span>
              <span className="bg-yellow rounded-full duration-02 ease-ease translate-y-24 mx-auto lg:hidden group-hover:hidden group-hover:translate-y-40 group-hover:opacity-0 w-8 h-8 right-3 -bottom-1 block">
                <PlusIcon className="p-2 w-full" />
              </span>
              <p className="text-b3 text-gray-800 text-center pointer-events-none	 opacity-0 group-hover:opacity-100 translate-y-16 group-hover:translate-y-0 transition-all duration-03 ease-ease">
                {listcontent ? listcontent[index] : 0}
              </p>
            </button>
            );
          })
        }
      </div>
    </Section>
  );
};

export default cardValues;
