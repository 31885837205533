import classNames from "classnames/bind";
import React, {ReactNode} from "react";
import { TitleFrontMatter } from "types/graphql";
import Section from "../section";
import ArrowRight from "assets/svg/arrow-right.svg";

type titleProps = Omit<TitleFrontMatter, "format"> & {
  children: ReactNode
  content?: string
  className?: string
};
const titleIntro = ({ title, content, className, children }: titleProps) => {
  return (
    <Section className={classNames("text-left lg:pb-0", className)} full>
      <div className={"grid grid-cols-16 "}>
        <div className={"flex-1 row-start-1 col-start-1 col-span-full z-1"}>
          <div className={classNames('flex', { "mb-6" : content })}>
            {title &&
              <h3 className={classNames('text-gray-500 mr-2 transition-text')} data-aos="custom">
                <span className="transition-content">
                  {title}
                  <ArrowRight className={"ml-4 fill-current inline-block w-5 text-gray-500"}/>
                </span>
                <span className="transition-layer"/>
              </h3>
            }
          </div>
              {content && (
                <div
                  className="titleImageIntro-content transition-content"
                  data-aos="custom"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
        </div>
        {children}
      </div>
    </Section>
  );
};

export default titleIntro;
